// Importing Element Plus components
import {
  ElPagination,
  ElDialog,
  ElAutocomplete,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElInput,
  ElInputNumber,
  ElRadio,
  ElRadioGroup,
  ElRadioButton,
  ElCheckbox,
  ElCheckboxButton,
  ElCheckboxGroup,
  ElSwitch,
  ElSelect,
  ElTreeSelect,
  ElOption,
  ElButton,
  ElButtonGroup,
  ElTable,
  ElTableColumn,
  ElPopover,
  ElTooltip,
  ElForm,
  ElFormItem,
  ElTabs,
  ElTabPane,
  ElTree,
  ElAlert,
  ElSlider,
  ElIcon,
  ElRow,
  ElCol,
  ElProgress,
  ElCarousel,
  ElCarouselItem,
  ElCollapse,
  ElCollapseItem,
  ElDivider,
  ElMessageBox,
  ElMessage,
  ElNotification,
  ElLoading,
  ElOptionGroup,
  ElBadge,
  ElCollapseTransition,
} from 'element-plus'

// Importing Element Plus styles
import 'element-plus/dist/index.css'

const components = {
  ElPagination,
  ElDialog,
  ElAutocomplete,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElInput,
  ElInputNumber,
  ElRadio,
  ElRadioGroup,
  ElRadioButton,
  ElCheckbox,
  ElCheckboxButton,
  ElCheckboxGroup,
  ElSwitch,
  ElSelect,
  ElTreeSelect,
  ElOption,
  ElButton,
  ElButtonGroup,
  ElTable,
  ElTableColumn,
  ElPopover,
  ElTooltip,
  ElForm,
  ElFormItem,
  ElTabs,
  ElTabPane,
  ElTree,
  ElAlert,
  ElSlider,
  ElIcon,
  ElRow,
  ElCol,
  ElProgress,
  ElCarousel,
  ElCarouselItem,
  ElCollapse,
  ElCollapseItem,
  ElDivider,
  ElMessage,
  ElNotification,
  ElLoading,
  ElOptionGroup,
  ElBadge,
  ElCollapseTransition,
  // Additional components as needed
}

export {
  ElPagination,
  ElDialog,
  ElAutocomplete,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElInput,
  ElInputNumber,
  ElRadio,
  ElRadioGroup,
  ElRadioButton,
  ElCheckbox,
  ElCheckboxButton,
  ElCheckboxGroup,
  ElSwitch,
  ElSelect,
  ElTreeSelect,
  ElOption,
  ElButton,
  ElButtonGroup,
  ElTable,
  ElTableColumn,
  ElPopover,
  ElTooltip,
  ElForm,
  ElFormItem,
  ElTabs,
  ElTabPane,
  ElTree,
  ElAlert,
  ElSlider,
  ElIcon,
  ElRow,
  ElCol,
  ElProgress,
  ElCarousel,
  ElCarouselItem,
  ElCollapse,
  ElCollapseItem,
  ElDivider,
  ElMessage,
  ElMessageBox,
  ElNotification,
  ElLoading,
  ElOptionGroup,
  ElBadge,
  ElCollapseTransition,
  // Additional components as needed
}

export default {
  install(app) {
    Object.values(components).forEach((component: any) => {
      app.component(component.name, component)
    })
    app.config.globalProperties.$message = ElMessage
    app.config.globalProperties.$msgbox = ElMessageBox
    app.config.globalProperties.$alert = ElMessageBox.alert
    app.config.globalProperties.$confirm = ElMessageBox.confirm
    app.config.globalProperties.$prompt = ElMessageBox.prompt
    app.config.globalProperties.$notify = ElNotification
    app.config.globalProperties.$notification = ElNotification
    app.directive('loading', ElLoading.directive)
    app.config.globalProperties.$loading = ElLoading.service
  },
}
